/* NBASupport.css */
.nbaSupport {
  padding: 80px 20px;
  background-color: #fff;
  color: #333;
  padding-left: 80px;
  padding-right: 80px;
}

.nbaSupport__header {
  text-align: left;
  margin-bottom: 40px;
}

.nbaSupport__title {
  font-size: 2rem;
  color: #333;
}

.nbaSupport__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 60px;
}

.nbaSupport__card {
  background-color: #f5f8fc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}

.nbaSupport__card--blue {
  background-color: #e4f0fc;
}

.nbaSupport__card--peach {
  background-color: #ffefe4;
}

.nbaSupport__card-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #000000;
  font-weight: 700;
}

.nbaSupport__card-description {
  font-size: 1rem;
  color: #000000;
  margin-bottom: 20px;
  flex-grow: 1;
  font-weight: 400;
}

.nbaSupport__badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  text-align: center;
  align-self: flex-start;

}

.nbaSupport__badge--blue {
  background-color: #4285f3;
  color: white;
  font-weight: 500;
}

.nbaSupport__badge--peach {
  background-color: #ff9547;
  color: white;
  font-weight: 500;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .nbaSupport {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 60px;
  }

  .nbaSupport__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }

  .nbaSupport__title {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .nbaSupport {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }

  .nbaSupport__grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .nbaSupport__title {
    font-size: 1.6rem;
    text-align: center;
  }

  .nbaSupport__card-title {
    font-size: 1.1rem;
  }

  .nbaSupport__card-description {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .nbaSupport {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
  }

  .nbaSupport__title {
    font-size: 1.4rem;
  }

  .nbaSupport__card {
    padding: 15px;
  }

  .nbaSupport__card-title {
    font-size: 1rem;
  }

  .nbaSupport__card-description {
    font-size: 0.9rem;
  }

  .nbaSupport__badge {
    font-size: 0.8rem;
  }
}