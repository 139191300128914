.nep2020 {
  background-color: #000;
  color: #fff;
  padding-top: 40px;
  text-align: left;
  margin-top: 0px;
}

.nep2020__content {
  margin-bottom: 20px;
  padding-left: 80px;
  padding-right: 80px;
}

.nep2020__title {
  font-size: 2rem;
  color: #ff9547;
  margin-bottom: 10px;
  font-weight: 700;
}

.nep2020__features {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.nep2020__feature {
  display: flex;
  align-items: center;
  font-size: 1rem;
  padding: 10px 20px;
  background-color: #222;
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: 500;
  flex-basis: calc(50% - 40px);
  /* Adjusting width for two items per row */
}

.nep2020__feature::before {
  content: "✔";
  color: #007bff;
  margin-right: 8px;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .nep2020__content {
    padding-left: 40px;
    padding-right: 40px;
  }

  .nep2020__title {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .nep2020__content {
    padding-left: 20px;
    padding-right: 20px;
  }

  .nep2020__title {
    font-size: 1.6rem;
    text-align: center;
  }

  .nep2020__features {
    flex-direction: column;
    gap: 15px;
  }

  .nep2020__feature {
    flex-basis: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .nep2020__content {
    padding-left: 10px;
    padding-right: 10px;
  }

  .nep2020__title {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 20px;
  }

  .nep2020__features {
    gap: 10px;
  }

  .nep2020__feature {
    font-size: 0.9rem;
    padding: 10px;
    flex-basis: 100%;
  }
}