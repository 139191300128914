/* SeamlessClassroom.css */
.seamlessClassroom {
  padding: 60px 20px;
  background-color: #fff;
  color: #333;
  text-align: left;
  padding-left: 80px;
  padding-right: 80px;
}

.seamlessClassroom__header {
  margin-bottom: 30px;
}

.seamlessClassroom__tagline {
  color: #ff9547;
  font-size: 24px;
  letter-spacing: 1px;
  margin-bottom: 1px;
  font-weight: 700;
}

.seamlessClassroom__title {
  font-size: 48px;
  margin-bottom: 20px;
  color: #000000;
  font-weight: 700;
}

.seamlessClassroom__subtitle {
  font-size: 1.2rem;
  color: #000000;
}

.seamlessClassroom__features {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.seamlessClassroom__feature {
  width: 30%;
  min-width: 280px;
  text-align: left;
}

.seamlessClassroom__feature-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 15px;
}

.seamlessClassroom__feature-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #142349;
  font-weight: 700px;
}

.seamlessClassroom__feature-description {
  font-size: 1rem;
  color: #666;
  font-weight: 500;
  color: #142349;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .seamlessClassroom {
    padding-left: 40px;
    padding-right: 40px;
  }

  .seamlessClassroom__title {
    font-size: 36px;
  }

  .seamlessClassroom__tagline {
    font-size: 20px;
  }

  .seamlessClassroom__subtitle {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .seamlessClassroom {
    padding-left: 20px;
    padding-right: 20px;
  }

  .seamlessClassroom__title {
    font-size: 32px;
  }

  .seamlessClassroom__subtitle {
    font-size: 1rem;
  }

  .seamlessClassroom__features {
    flex-direction: column;
    align-items: center;
  }

  .seamlessClassroom__feature {
    width: 100%;
    text-align: center;
  }

  .seamlessClassroom__feature-title {
    font-size: 1.15rem;
  }

  .seamlessClassroom__feature-description {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .seamlessClassroom {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .seamlessClassroom__tagline {
    font-size: 18px;
  }

  .seamlessClassroom__title {
    font-size: 28px;
  }

  .seamlessClassroom__subtitle {
    font-size: 0.9rem;
    margin-bottom: 30px;
  }

  .seamlessClassroom__feature-title {
    font-size: 1rem;
  }

  .seamlessClassroom__feature-description {
    font-size: 0.85rem;
  }
}