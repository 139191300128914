/* EnhancedTools.css */
.enhancedTools {
  padding: 40px 20px;
  background-color: #4285f3;
  color: #fff;
  text-align: left;
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 30px;
}

.enhancedTools__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
  margin-bottom: 40px;
}

.enhancedTools__item {
  background-color: #4a90e2;
  border-radius: 10px;
  padding: 20px;
}

.enhancedTools__item--wide {
  grid-column: span 2;
}

.enhancedTools__image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.enhancedTools__title {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: 700;
}

.enhancedTools__description {
  font-size: 1rem;
  color: #e1e9f0;
  font-weight: 400;
  color: #ffffff;
}

.enhancedTools__cta {
  text-align: center;
}

.enhancedTools__button {
  background-color: #ff9547;
  color: #ffffff;
  padding: 15px 30px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 700;
}

.enhancedTools__button:hover {
  background-color: #e68a3f;
}


/* Responsive Design */
@media (max-width: 1024px) {
  .enhancedTools {
    padding-left: 40px;
    padding-right: 40px;
  }

  .enhancedTools__grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .enhancedTools__item--wide {
    grid-column: span 1;
  }
}

@media (max-width: 768px) {
  .enhancedTools {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .enhancedTools__title {
    font-size: 1.4rem;
  }

  .enhancedTools__description {
    font-size: 0.95rem;
  }

  .enhancedTools__button {
    padding: 12px 25px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .enhancedTools {
    padding: 30px 10px;
  }

  .enhancedTools__title {
    font-size: 1.25rem;
  }

  .enhancedTools__description {
    font-size: 0.9rem;
  }

  .enhancedTools__button {
    padding: 10px 20px;
    font-size: 0.85rem;
  }

  .enhancedTools__grid {
    grid-template-columns: 1fr;
    gap: 15px;
  }

  .enhancedTools__item {
    padding: 15px;
  }
}