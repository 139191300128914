/* WhyCodeXodus.css */
.whyCodeXodus {
  padding: 40px 75px;
  background-color: #fff;
  color: #333;
  text-align: center;
  
}

.whyCodeXodus__title {
  font-size: 2.5rem;
  margin-bottom: 60px;
  color: #000000;
}

.whyCodeXodus__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.whyCodeXodus__images {
  flex: 1;
  display: flex;
  gap: 20px;
}

.whyCodeXodus__image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.whyCodeXodus__info {
  flex: 1;
  padding: 0 20px;
  text-align: justify;
}

.whyCodeXodus__description {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.whyCodeXodus__features {
  list-style: none;
  padding: 0;
  text-align: left;
}



.whyCodeXodus__feature {
  font-size: 1rem;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.whyCodeXodus__feature::before {
  content: "✔";
  color: #007bff;
  margin-right: 10px;
}

.whyCodeXodus__cards {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.whyCodeXodus__card {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  display: flex;
  align-items: center;
}

.whyCodeXodus__card--programs {
  background-color: #FF9547;
  color: #fff;
}

.whyCodeXodus__card-icon {
  font-size: 2rem;
  margin-right: 15px;
}

.whyCodeXodus__card-info {
  text-align: left;
}

.whyCodeXodus__card--popular {
  flex-direction: row;
  background-color: #e9ecef;
}

.whyCodeXodus__card-image img {
  width: 100px;
  height: auto;
  border-radius: 10px;
  margin-right: 15px;
}

.whyCodeXodus__badge {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  display: inline-block;
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .whyCodeXodus {
    padding: 30px 20px;
  }

  .whyCodeXodus__title {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .whyCodeXodus__content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .whyCodeXodus__info {
    padding: 0;
  }

  .whyCodeXodus__description {
    font-size: 1.1rem;
  }

  .whyCodeXodus__cards {
    flex-direction: column;
    gap: 20px;
  }

  .whyCodeXodus__card {
    max-width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .whyCodeXodus__card--popular {
    flex-direction: column;
  }

  .whyCodeXodus__card-image img {
    width: 60px;
  }
}

@media (max-width: 480px) {
  .whyCodeXodus {
    padding: 20px 15px;
  }

  .whyCodeXodus__title {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }

  .whyCodeXodus__description {
    font-size: 1rem;
  }

  .whyCodeXodus__features {
    text-align: center;
  }

  .whyCodeXodus__feature {
    font-size: 0.9rem;
  }

  .whyCodeXodus__card {
    padding: 15px;
  }

  .whyCodeXodus__badge {
    font-size: 0.8rem;
  }
}