/* NAACSupport.css */
.naacSupport {
  padding: 40px 20px;
  background-color: #e4f0fc;
  color: #333;
  text-align: left;
  margin-top: 0px;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 80px;
}

.naacSupport__header {
  text-align: left;
  margin-bottom: 40px;
}

.naacSupport__title {
  font-size: 2rem;
  color: #000000;
  margin-bottom: 10px;
  font-weight: 700;
}

.naacSupport__description {
  font-size: 1.2rem;
  color: #000000;
  margin-bottom: 20px;
  margin: 0 auto;
  font-weight: 400;
}

.naacSupport__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.naacSupport__item {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.naacSupport__item-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #333;
}

.naacSupport__badge {
  display: inline-block;
  background-color: #4a90e2;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 500;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .naacSupport {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 60px;
  }

  .naacSupport__title {
    font-size: 1.8rem;
  }

  .naacSupport__description {
    font-size: 1.1rem;
  }

  .naacSupport__grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .naacSupport {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }

  .naacSupport__title {
    font-size: 1.6rem;
    text-align: center;
  }

  .naacSupport__description {
    font-size: 1rem;
    text-align: center;
  }

  .naacSupport__item {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .naacSupport {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
  }

  .naacSupport__title {
    font-size: 1.5rem;
  }

  .naacSupport__description {
    font-size: 0.9rem;
  }

  .naacSupport__item-title {
    font-size: 1.1rem;
  }

  .naacSupport__badge {
    font-size: 0.8rem;
  }
}