.demoForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px;
  padding-left: 80px;
  padding-right: 80px;
  background-color: #fff;
  border-radius: 10px;
}

.demoForm__image {
  flex: 1;
  max-width: 100%;
}

.demoForm__image img {
  width: 150%;
  height: auto;
}

.demoForm__form-container {
  flex: 1;
  max-width: 40%;
  background-color: #ff8f47;
  padding: 60px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.demoForm__title {
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  font-weight: 500px;
}

.demoForm__title span {
  color: #333;
  font-weight: bold;
}

.demoForm__form {
  display: flex;
  flex-direction: column;
}

.demoForm__input {
  padding: 15px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  background-color: #fff;
  color: #333;
}

.demoForm__input::placeholder {
  color: #999;
}

.demoForm__button {
  padding: 15px;
  background-color: #4a90e2;
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 700;
}

.demoForm__button:hover {
  background-color: #3b78c3;
}

/* Responsive Design */

/* Tablet View */
@media (max-width: 1024px) {
  .demoForm {
    padding: 60px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .demoForm__form-container {
    max-width: 100%;
    padding: 40px;
  }

  .demoForm__title {
    font-size: 1.8rem;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .demoForm {
    padding: 40px 20px;
  }

  .demoForm__image {
    order: 2;
    margin-top: 20px;
    text-align: center;
  }

  .demoForm__image img {
    width: 80%;
 
  }

  .demoForm__form-container {
   
    padding: 30px;
  }

  .demoForm__title {
    font-size: 1.6rem;
    text-align: center;
  }
}

/* Extra Small Devices */
@media (max-width: 480px) {
  .demoForm {
    padding: 20px;
  }

  .demoForm__input {
    font-size: 1rem;
    padding: 12px;
  }

  .demoForm__button {
    font-size: 1rem;
    padding: 12px;
  }

  .demoForm__title {
    font-size: 1.5rem;
  }
}