/* Banner.css */
.banner {
  background-image: url("../Images/hero.svg");
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65vh;
}

.code {
  font-size: 86px;
  font-weight: 700;
}

.banner__content {
  max-width: 800px;
  margin: 0 auto;

  padding: 20px;
  border-radius: 10px;
}

.banner__title {
  margin-bottom: 20px;
}

.banner__highlight {
  color: #FF9547;
  font-size: 40px;
  font-weight: 700;
}

.banner__description {
  font-size: 30px;
  margin-bottom: 30px;
}

.banner__actions {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.banner__button {
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.banner__button--primary {
  background-color: #FF9547;
  color: #fff;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 4px;
  font-weight: 900;
}

.banner__button--secondary {
  background-color: #ffffff;
  color: #4285f3;
  padding: 20px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 4px;
  font-weight: 900;
}

.banner__button--primary:hover,
.banner__button--secondary:hover {
  opacity: 0.8;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .banner {
    height: auto;
    padding: 60px 20px;
  }

  .code {
    font-size: 48px;
  }

  .banner__highlight {
    font-size: 32px;
  }

  .banner__description {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .banner__button {
    font-size: 1rem;
    padding: 12px 25px;
  }
}

@media (max-width: 480px) {
  .banner {
    padding: 40px 10px;
  }

  .code {
    font-size: 36px;
  }

  .banner__highlight {
    font-size: 28px;
  }

  .banner__description {
    font-size: 18px;
  }

  .banner__actions {
    flex-direction: column;
    gap: 15px;
  }

  .banner__button {
    font-size: 0.9rem;
    width: 100%;
    padding: 12px 20px;
  }
}