/* Base styles */
body {
  font-family: Arial, sans-serif;
}

.placement-readiness {
  padding: 60px;
  background-color: #f4f4f4;
  padding-left: 80px;
  padding-right: 80px;
  margin-top: 30px;
}

.placement-readiness__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;
}

.placement-readiness__image {
  width: 100%;
  max-width: 700px;
  height: auto;
}

.placement-readiness__title {
  font-size: 48px;
  font-weight: bold;
  color: #000000;
  width: 50%;
  text-align: left;
  font-weight: 700;
}

/* Benefits Section */
.placement-readiness__benefits {
  display: flex;
  justify-content: space-between;
  gap: 0px;
  margin-bottom: 40px;
  margin-top: -40px;
}

.benefit {
  background-color: #4285f3;
  padding: 20px;
  border-radius: 0px;
  color: white;
  flex: 1;
  text-align: left;
}

.benefit--orange {
  background-color: #ff9547;
}

.benefit--black {
  background-color: #000000;
}

.benefit__title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: 700;
}

.benefit__description {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  color: #ffffff;
}

/* SDG Section */
.placement-readiness__sdg {
  padding: 20px;
  background-color: #e0f7fa;
  border-radius: 8px;

}

.sdg__title {
  font-size: 24px;
  margin-bottom: 20px;
}

.sdg__description {
  font-size: 16px;
  margin-bottom: 20px;
}

.sdg__list {
  list-style: none;
  padding: 0;
}

.sdg__item {
  font-size: 16px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.sdg__item:last-child {
  border-bottom: none;
}

/* Responsive Design */

/* Tablet View */
@media (max-width: 1024px) {
  .placement-readiness {
    padding: 40px 20px;
  }

  .placement-readiness__title {
    font-size: 2.5rem;
    width: 100%;
  }

  .placement-readiness__benefits {
    gap: 20px;
    flex-wrap: wrap;
  }

  .benefit {
    flex-basis: calc(50% - 20px);
    margin-bottom: 20px;
  }

  .sdg__title {
    font-size: 1.3rem;
  }

  .sdg__description {
    font-size: 0.9rem;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .placement-readiness {
    padding: 30px 10px;
  }

  .placement-readiness__header {
    flex-direction: column;
    text-align: center;
  }

  .placement-readiness__title {
    font-size: 2rem;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .placement-readiness__benefits {
    flex-direction: column;
    gap: 20px;
  }

  .benefit {
    flex-basis: 100%;
  }

  .sdg__title {
    font-size: 1.2rem;
  }

  .sdg__description {
    font-size: 0.9rem;
  }

  .sdg__item {
    font-size: 0.9rem;
    padding: 8px 0;
  }
}

/* Extra Small Devices */
@media (max-width: 480px) {
  .placement-readiness {
    padding: 20px;
  }

  .placement-readiness__title {
    font-size: 1.8rem;
  }

  .benefit {
    padding: 15px;
  }

  .benefit__title {
    font-size: 1.1rem;
  }

  .benefit__description {
    font-size: 0.8rem;
  }

  .sdg__title {
    font-size: 1rem;
  }

  .sdg__description {
    font-size: 0.8rem;
  }
}